import type { FC, ReactNode } from "react";

import RichPortableText from "components/rich-text";
import Image from "next/image";
import Link from "next/link";
import AICPALogo from "public/images/AICPA-SOC-Service-Orgs.png";
import SOC2Logo from "public/images/soc-2-blue-logo.png";
import EnerfloBestInShow2024 from "public/images/enerflo-best-in-show-2024.png";

import { faFacebook, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortableTextBlock } from "@portabletext/types";

import { loginUrl, navigation, NavigationItem, NavItemTypes } from "../navigation";
import { sendAnalyticEvent } from "../page/use-page";
import styles from "./index.module.css";

export enum FooterVariants {
  Default = "default",
  Custom = "custom",
}

const enerfloTrustLink = "https://trust.enerflo.com";

interface SocialNavigationItem extends NavigationItem {
  icon: FC<any>;
}

const socialNavigation: SocialNavigationItem[] = [
  {
    name: "YouTube",
    href: "https://www.youtube.com/@enerflo-your-solar-platform",
    icon: props => <FontAwesomeIcon {...props} icon={faYoutube} size="2x" />,
    type: NavItemTypes.Link,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/enerflo/",
    icon: props => <FontAwesomeIcon {...props} icon={faLinkedinIn} size="2x" />,
    type: NavItemTypes.Link,
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/Enerflo-907184749636150/",
    icon: props => <FontAwesomeIcon {...props} icon={faFacebook} size="2x" />,
    type: NavItemTypes.Link,
  },
];

const DefaultFooterContent = () => (
  <>
    <h2 id="footer-heading" className="sr-only">
      Enerflo
    </h2>
    <div className="py-10 px-4 sm:px-6 lg:px-8">
      <div className="lg:container mx-auto">
        <div className="grid mb-6 sm:grid-cols-12 md:mb-0">
          <div className="sm:col-span-8 xl:col-span-9 flex items-center">
            <Image src="/enerflo-dark.png" alt="Enerflo Logo" width={150} height={32} aria-hidden="true" />
          </div>
          <div className="sm:col-span-4 xl:col-span-3 flex justify-start items-center sm:justify-end">
            <div className="flex space-x-2 mt-6 sm:mt-0">
              <a
                href={loginUrl}
                className="btn btn-navy text-sm md:text-base whitespace-nowrap"
                onClick={() => sendAnalyticEvent("Utility Button", "Buttons", "Login")}
              >
                Login Now
              </a>
              <Link href="/demo">
                <a className="btn btn-primary text-sm md:text-base whitespace-nowrap">Discover Enerflo</a>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid 2xs:grid-cols-12 gap-4">
          <div className="grid grid-cols-2 gap-6 2xs:col-span-12 sm:col-span-8 md:col-span-6 lg:col-span-4 md:mt-10">
            <div className="mt-4 md:mt-0">
              <LinkListItem {...navigation.features} />
            </div>
            <div className="mt-4 md:mt-0">
              <LinkListItem {...navigation.integrations} />
            </div>
            <div className="hidden sm:block col-span-2" />
            <div className="mt-4 md:mt-0">
              <LinkListItem {...navigation.about} />
            </div>
            <div className="space-y-8">
              <div className="mt-4 md:mt-0">
                <LinkListItem name="Book a Demo" href="/demo" type={NavItemTypes.Link} />
              </div>
              <div className="mt-4 md:mt-0">
                <LinkListItem name="Get Support" href="/support" type={NavItemTypes.Link} />
              </div>
              <div className="mt-4 md:mt-0">
                <LinkListItem
                  name="Login to Enerflo"
                  href={loginUrl}
                  type={NavItemTypes.Link}
                  analyticEvent={{
                    event: "Utility Button",
                    category: "Buttons",
                    label: "Login",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="2xs:col-span-6 xs:col-span-8 sm:col-span-4 md:col-span-3 lg:col-span-4 mt-6 self-center sm:self-start sm:mt-0 md:mt-10">
            <a
              href="https://enerflo.com/resources/enerflo-wins-solar-power-world-2024-residential-best-in-show"
              className={styles.bestInShow2024}
            >
              <Image
                src={EnerfloBestInShow2024}
                alt="Enerflo Best In Show Award 2024"
                title="Enerflo Best In Show Award 2024"
              />
            </a>
          </div>
          <div className="2xs:col-span-6 xs:col-span-4 sm:col-span-12 md:col-span-3 lg:col-span-4 flex flex-col-reverse sm:flex-row md:flex-col justify-between gap-6 2xs:gap-4">
            <div className="flex justify-start items-end 2xs:items-center 2xs:justify-end sm:items-end sm:justify-start md:items-center md:justify-end space-x-2 xs:mt-9">
              {socialNavigation.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm text-gray-400 hover:text-gray-500 py-1 px-2 transition-colors"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <div className={styles.soc2Logo}>
              <a href={enerfloTrustLink}>
                <Image src={AICPALogo} alt="SOC for Service Organizations." title="SOC for Service Organizations" />
              </a>
              <a href={enerfloTrustLink}>
                <Image
                  src={SOC2Logo}
                  alt="Enerflo is continually monitoring its overall security posture with the help of Drata. View Enerflo's SOC 2 Type 1 & 2 Reports in the Trust Center."
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export type Footer = { show?: boolean; variant?: FooterVariants; content?: PortableTextBlock };
export const AppFooter = ({ show = true, variant, content }: Footer) => {
  let footerContent;

  if (show) {
    if (variant === FooterVariants.Custom) {
      if (content) {
        footerContent = (
          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="lg:container mx-auto">
              <RichPortableText value={content} />
            </div>
          </div>
        );
      }
    } else {
      footerContent = <DefaultFooterContent />;
    }
  }

  return (
    <footer className="bg-white border-t border-gray-200" aria-labelledby="footer-heading">
      {footerContent}
      <LegalBar />
    </footer>
  );
};

function LinkListHeader({ title }: { title: ReactNode }) {
  return <h3 className="text-navy tracking-wider">{title}</h3>;
}

const LinkListItem = ({ href, name, items }: NavigationItem) => (
  <>
    <LinkListHeader
      title={
        <Link href={href}>
          <a>{name}</a>
        </Link>
      }
    />
    {!!items?.length && <LinkList links={items} parentHref={href} />}
  </>
);

function LinkList({ links, parentHref }: { links: NavigationItem[]; parentHref?: string }) {
  return (
    <ul role="list" className="mt-4 space-y-4">
      {links
        // Don't render dropdown default items in the footer
        .filter(item => item.href !== parentHref)
        .map(item => (
          <li key={item.name}>
            <Link href={item.href}>
              <a className="text-sm text-gray-400 hover:text-navy hover:text-opacity-80">{item.name}</a>
            </Link>
          </li>
        ))}
    </ul>
  );
}

const currentYear = new Date().getFullYear();

function LegalBar() {
  return (
    <div className={styles.legal}>
      <p className={styles.legalContent}>
        &copy; {currentYear} Enerflo
        <span className={styles.legalSeparator}> | </span>
        <Link href="/terms">
          <a className={styles.legalLink}>Terms of Service</a>
        </Link>
        <span className={styles.legalSeparator}> | </span>
        <Link href="/privacy">
          <a className={styles.legalLink}>Privacy Policy</a>
        </Link>
        <span className={styles.legalSeparator}> | </span>
        <a className={styles.legalLink} href={enerfloTrustLink}>
          Trust Center
        </a>
        <span className={styles.legalSeparator}> | </span>
        <Link href="/cookies">
          <a className={styles.legalLink}>Cookie Notice</a>
        </Link>
      </p>
    </div>
  );
}
