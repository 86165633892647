import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { BaseSyntheticEvent, useCallback, useEffect, useRef } from "react";

import { Disclosure, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import { headerNavItems, NavigationItem, NavItemTypes } from "../navigation";
import { sendAnalyticEvent } from "../page/use-page";
import styles from "./index.module.css";
import NavItem, { isItemCurrent } from "./nav-item";

export const navId = "app_nav";
const mobileNavId = "mobile_nav";

export const clickedInNodeList = (node: HTMLDivElement | null, e: BaseSyntheticEvent) => {
  if (!node) {
    return false;
  }

  if (e.target === node || node.contains(e.target)) {
    return true;
  }

  return false;
};

const HeaderContent = ({
  open,
  close,
  navItems = headerNavItems,
}: {
  open: boolean;
  close: () => void;
  navItems?: NavigationItem[];
}) => {
  const navContainer = useRef<HTMLDivElement>(null);
  const headerItems = navItems.filter(i => !i.footerOnly);

  const handleClick = useCallback(
    e => {
      if (open && !clickedInNodeList(navContainer.current, e)) {
        // Close the nav when user clicks away from it
        close();
      }
    },
    [open, close]
  );

  const handleScroll = useCallback(
    e => {
      // If scrolling outside of the nav, close it
      if (open && e.target !== document.getElementById(mobileNavId)) {
        close();
      }
    },
    [open, close]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleClick, handleScroll]);

  return (
    <div className="relative" ref={navContainer}>
      <div className="lg:container mx-auto md:px-4">
        <div className="relative flex items-center justify-between h-16 z-40">
          {!!headerItems?.length && (
            <div className="absolute inset-y-0 left-2 flex items-center md:hidden">
              <MobileMenuButton open={open} />
            </div>
          )}
          <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link href="/">
                <a className="md:ml-2 lg:ml-0">
                  <Image src="/enerflo-dark.png" alt="Enerflo Logo" width={150} height={32} />
                </a>
              </Link>
            </div>
          </div>
          {!!headerItems?.length && (
            <div className="absolute inset-y-0 right-0 flex items-center justify-end pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
              <div className="hidden md:block md:mr-2 lg:mr-0">
                <DesktopNavigationItems navItems={headerItems} />
              </div>
            </div>
          )}
        </div>
      </div>

      {!!headerItems?.length && (
        <Transition
          show={open}
          enter="ease-out duration-300 absolute w-full"
          enterFrom="opacity-0 bottom-10"
          enterTo="opacity-100 bottom-0"
          leave="ease-in duration-200 absolute w-full"
          leaveFrom="opacity-100 bottom-0"
          leaveTo="opacity-0 bottom-10"
        >
          <Disclosure.Panel className="transition-all fixed h-full w-full z-30 md:hidden">
            <MobileNavigationItems navItems={headerItems} />
          </Disclosure.Panel>
        </Transition>
      )}
    </div>
  );
};

export const AppHeader = ({ navItems }: { navItems?: NavigationItem[] }) => {
  return (
    <Disclosure as="nav" className="bg-white sticky top-0 z-50" id={navId}>
      {disclosureProps => <HeaderContent {...disclosureProps} navItems={navItems} />}
    </Disclosure>
  );
};

function MobileMenuButton({ open }: { open: boolean }) {
  const Icon = open ? XIcon : MenuIcon;

  return (
    <Disclosure.Button className={styles.mobileMenuButton}>
      <span className="sr-only">Open main menu</span>
      <Icon className={styles.mobileMenuButtonIcon} aria-hidden="true" />
    </Disclosure.Button>
  );
}

const MobileNavLink = ({ item, isDefault }: { item: NavigationItem; isDefault?: boolean }) => {
  const { href, name, analyticEvent, items } = item;
  const router = useRouter();
  // Don't render footer-specific items, or those used as the default item in nav dropdowns
  const headerItems = items?.filter(i => !i.footerOnly && item.href !== i.href);
  const isCurrent = isItemCurrent(item, router, isDefault);
  const handleClick = useCallback(() => {
    if (analyticEvent) {
      sendAnalyticEvent(analyticEvent.event, analyticEvent.category, analyticEvent.label);
    }
  }, [analyticEvent]);

  return (
    <div>
      <Disclosure.Button
        key={name}
        as="a"
        href={href}
        className={clsx(styles.mobileNavLink, !isCurrent && styles.inactiveNavLink)}
        aria-current={isCurrent ? "page" : undefined}
        onClick={handleClick}
      >
        {name}
      </Disclosure.Button>
      {!!headerItems?.length && (
        <div className="text-sm">
          {headerItems.map(i => (
            <MobileNavLink key={i.href} item={i} isDefault={i.href === href} />
          ))}
        </div>
      )}
    </div>
  );
};

function DesktopNavigationItems({ navItems }: { navItems: NavigationItem[] }) {
  return (
    <div className={styles.desktopNav}>
      {navItems.map(item => (
        <NavItem key={item.name} item={item} />
      ))}
    </div>
  );
}

function MobileNavigationItems({ navItems }: { navItems: NavigationItem[] }) {
  const { links, buttons } = navItems.reduce<{ links: NavigationItem[]; buttons: NavigationItem[] }>(
    (final, item) => {
      if (item.type === NavItemTypes.Link) {
        final.links.push(item);
      } else if (item.type === NavItemTypes.Button) {
        final.buttons.push(item);
      }
      return final;
    },
    { links: [], buttons: [] }
  );

  return (
    <div className={styles.mobileNav} id={mobileNavId}>
      <div className="grid grid-cols-1 2xs:grid-cols-2 gap-4">
        {links?.map(item => (
          <MobileNavLink key={item.href} item={item} />
        ))}
      </div>
      <div className="grid grid-cols-1 2xs:grid-cols-2 gap-4 items-center">
        {buttons?.map(item => (
          <NavItem key={item.href} item={item} className={styles.navButton} />
        ))}
      </div>
    </div>
  );
}
